<template>
  <div>
    <v-container>
      <v-row>
        <v-col cols="12">
          <v-card outlined class="mb-6">
            <v-card-title
              class="d-flex justify-start align-center grey lighten-3"
            >
              <div class="flex-grow-1">Puppies</div>

              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    small
                    depressed
                    color="green lighten-4 green--text"
                    v-on="on"
                    @click="$refs.puppyForm.openForm()"
                  >
                    <v-icon small>mdi-plus</v-icon>
                  </v-btn>
                </template>
                <span>Add New Puppy</span>
              </v-tooltip>
            </v-card-title>
            <v-divider></v-divider>

            <v-data-table
              :headers="tableHeaders"
              :items="litter.puppies"
              no-data-text="No Puppies"
              :items-per-page="-1"
              disable-pagination
              hide-default-footer
              :item-class="row_classes"
            >
              <template v-slot:item.defects="{ item }">
                {{ defects(item) }}
              </template>

              <template v-slot:item.gender="{ item }">
                <div v-if="item.gender">
                  {{ item.gender == "F" ? "Female" : "Male" }}
                </div>
              </template>

              <template v-slot:item.actions="{ item }">
                <v-btn
                  x-small
                  depressed
                  class="mr-2"
                  color="green lighten-4 green--text"
                  :to="{
                    name: 'module-lhl-dogs-individual',
                    params: { dogId: item.id },
                  }"
                >
                  <v-icon x-small>mdi-eye</v-icon>
                </v-btn>
              </template>
            </v-data-table>
          </v-card>

          <v-card outlined class="mb-6">
            <v-card-title
              class="d-flex justify-start align-center grey lighten-3"
            >
              <div class="flex-grow-1">Applications</div>
            </v-card-title>
            <v-divider></v-divider>

            <v-data-table
              :headers="tableApplicationHeaders"
              :items="litter.applications"
              no-data-text="No Applications"
              :header-props="{ sortIcon: null }"
              disable-pagination
              hide-default-footer
              :items-per-page="-1"
            >
              <template v-slot:item.confirm="{ item, index }">
                <v-checkbox
                  v-model="fields.dogs[index].confirmed"
                  :true-value="1"
                  :false-value="0"
                  @change="changePuppy(item.id)"
                ></v-checkbox>
              </template>

              <template v-slot:item.size="{ item }">
                1st: {{ item.size_first_choice }}

                <div v-if="item.size_second_choice">
                  2st: {{ item.size_second_choice }}
                </div>
                <div v-if="item.size_third_choice">
                  3st: {{ item.size_third_choice }}
                </div>
              </template>

              <template v-slot:item.coat="{ item }">
                1st: {{ item.coat_type_first_choice }}

                <div v-if="item.coat_type_second_choice">
                  2st: {{ item.coat_type_second_choice }}
                </div>
                <div v-if="item.coat_type_third_choice">
                  3st: {{ item.coat_type_third_choice }}
                </div>
              </template>

              <template v-slot:item.colours="{ item }">
                {{ displayColors(item) ? displayColors(item) : "None" }}
              </template>

              <template v-slot:item.gender_preference="{ item }">
                <div v-if="item.gender_preference == 'prefer_male'">
                  Prefer Male
                </div>
                <div v-else-if="item.gender_preference == 'prefer_female'">
                  Prefer Female
                </div>
                <div v-else-if="item.gender_preference == 'male'">Male</div>
                <div v-else-if="item.gender_preference == 'female'">Female</div>
              </template>

              <template v-slot:item.regular_visitors="{ item }">
                {{ item.regular_visitors ? "Yes" : "" }}
              </template>

              <template v-slot:item.under_18s="{ item }">
                {{ item.under_18s ? "Yes" : "" }}
              </template>

              <template v-slot:item.name="{ item }">
                <router-link
                  :to="{
                    name: 'module-lhl-applications-puppy-individual',
                    params: { applicationId: item.id },
                  }"
                  >{{
                    item.admins.customer
                      ? item.admins.customer.full_name
                      : item.admins.first_name + " " + item.admins.last_name
                  }}</router-link
                >
              </template>

              <template v-slot:item.household_disability="{ item }">
                {{ item.household_disability ? "Yes" : "" }}
              </template>

              <template v-slot:item.puppy="{ item, index }">
                <v-autocomplete
                  label="Puppy"
                  v-model="fields.dogs[index].dog_id"
                  :items="puppies"
                  item-text="name"
                  item-value="id"
                  outlined
                  density="compact"
                  clearable
                  background-color="white"
                  @change="changePuppy(item.id)"
                ></v-autocomplete>
              </template>

              <template v-slot:item.additional_animals="{ item }">
                {{ item.additional_animals ? "Yes" : "" }}
              </template>

              <template v-slot:item.payment="{ item }">
                <div v-if="item.admins.payments_final_balance">Balance</div>
                <div v-else-if="item.admins.payments_interim">Interim</div>
                <div v-else-if="item.admins.payments_deposit">Deposit</div>

                <div v-else>No Payment Yet</div>
              </template>

              <template v-slot:item.actions="{ item }">
                <v-btn
                  x-small
                  depressed
                  class="mr-2"
                  color="green lighten-4 green--text"
                >
                  <v-icon x-small>mdi-eye</v-icon>
                </v-btn>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>

      <PuppyForm ref="puppyForm" :litter="litter" />
    </v-container>
  </div>
</template>

<script>
import PuppyForm from "../components/PuppyForm";
export default {
  components: { PuppyForm },

  data() {
    return {
      searchTerm: "",

      tableApplicationHeaders: [
        { text: "Confirmed?", value: "confirm", width: "10px" },
        { text: "Application ID", value: "admins.title" },
        { text: "Name", value: "name" },
        { text: "Size", value: "size" },
        { text: "Colour", value: "colours" },
        { text: "Coat", value: "coat" },
        { text: "Gender", value: "gender_preference" },
        { text: "Puppy", value: "puppy", width: "200px" },
        {
          text: "Allergies",
          value: "does_anyone_in_your_household_have_allergies_or_asthma",
        },
        { text: "Children", value: "under_18s" },
        { text: "Disabled", value: "household_disability" },
        { text: "Experience", value: "adult_owner_status" },
        { text: "Other Animals", value: "additional_animals" },
        { text: "Strangers", value: "regular_visitors" },
        { text: "Payment", value: "payment" },
      ],

      colors: [
        { text: "Black/Silver/Blue", value: "black_silver_blue" },
        {
          text: "Chocolate/Café/Lavender/Parchment",
          value: "chocolate_cafe_lavender_parchment",
        },
        { text: "Red/Gold", value: "red_gold" },
        { text: "Gold/Cream", value: "gold_cream" },
        { text: "Caramel", value: "caramel" },
        { text: "Black Parti", value: "black_parti" },
        { text: "Chocolate Parti", value: "chocolate_parti" },
      ],

      fields: { dogs: [] },
      dogs: [],
      fieldsDog: { dog_id: null, confirmed: 0 },

      tableHeaders: [
        { text: "ID", value: "identifier", sortable: false },
        { text: "Microchip", value: "microchip", sortable: false },
        { text: "Gender", value: "gender", sortable: false },
        { text: "Colour", value: "colour.name", sortable: false },
        { text: "Temperament", value: "temperament", sortable: false },
        { text: "Defects", value: "defects", sortable: false },
        { text: "Actions", value: "actions", sortable: false, align: "right" },
      ],
    };
  },

  beforeMount: function () {
    this.litter.applications.forEach((application) => {
      this.fields.dogs.push({
        id: application.id,
        dog_id: application.admins.dog_id ? application.admins.dog_id : null,
        confirmed: application.admins.confirmed == 1 ? 1 : 0,
      });
    });
  },

  computed: {
    puppies() {
      let puppies = this.$store.getters["lhl/dogs/puppies"];

      puppies = puppies.filter(
        (c) => c.litter_id == this.$route.params.litterId
      );

      return puppies;
    },

    litter() {
      return this.$store.getters["lhl/litters/get"];
    },
  },

  methods: {
    row_classes(item) {
      if (item.puppy_application) return "danger";
    },

    cleanArray: function (actual) {
      var newArray = new Array();
      for (var i = 0; i < actual.length; i++) {
        if (actual[i]) {
          newArray.push(actual[i]);
        }
      }
      return newArray;
    },

    displayColors(application) {
      let colors = [];

      this.colors.forEach((color) => {
        if (application[color.value]) {
          colors[application[color.value]] = color.text;
        }
      });

      colors = this.cleanArray(colors);

      return colors.join(", ");
    },

    changePuppy(applicationId) {
      this.loading = true;
      this.errors = {};

      let array = [];

      this.fields.filter;

      array = this.fields.dogs.filter((c) => c.id == applicationId);

      array.forEach((dog) => {
        this.fieldsDog.dog_id = dog.dog_id;
        this.fieldsDog.confirmed = dog.confirmed;
      });

      let payload = {
        appId: this.$route.params.id,
        fields: this.fieldsDog,
        applicationId: applicationId,
      };

      this.fieldsDog.type = "set-puppy";

      this.$store
        .dispatch("lhl/applications/saveApplicationPuppy", payload)
        .then(() => {
          this.fieldsDog.dog_id = null;
          this.fieldsDog.confirmed = null;
        })
        .catch();
    },

    defects(dog) {
      let defects = [];

      if (dog.defects) {
        if (dog.defects.base_narrow_canines) {
          defects.push("Base Narrow Canines");
        }
        if (dog.defects.umbilical_hernia) {
          defects.push("Umbilical Hernia");
        }
        if (dog.defects.heart_murmur) {
          defects.push("Heart Murmur");
        }
        if (dog.defects.underbite) {
          defects.push("Underbite");
        }
        if (dog.defects.overbite) {
          defects.push("Overbite");
        }
        if (dog.defects.open_face) {
          defects.push("Open Face");
        }
      }

      return defects.join(", ");
    },
  },
};
</script>

<style lang="css">
.danger {
  background-color: #f2dede;
}
</style>
